<template>
  <v-container class="bannerFull" fluid>
    <v-row class="headerText">
      <v-col align-self="center">
        <h1 class="white--text ml-4">Publikasi Hasil Survei</h1>
      </v-col>
    </v-row>
    <v-container fluid>
      <v-row>
        <v-col align-self="center">
          <iframe
            src="https://drive.google.com/file/d/1Wmwq_JxUgo3UhhtS73Hp7n8VhLd4b5ow/preview"
            width="100%"
            height="600px"
            allow="autoplay"
          ></iframe>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>