import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import HelloWorld from '../components/HelloWorld.vue'
import Search from '../components/Search.vue'
import Unduhan from '../components/Unduhan.vue'
import DaftarSurvei from '../components/DaftarSurvei.vue'
import SurveiOnline from '../components/SurveiOnline.vue'
import HasilSurvei from '../components/HasilSurvei.vue'
import PublikasiHasilSurvei from '../components/PublikasiHasilSurvei.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // name: 'Helloworld',
    component: HelloWorld
  },
  {
    path: '/unduhan',
    name: 'Unduhan',
    component: Unduhan
  },
  {
    path: '/daftarsurvei',
    name: 'DaftarSurvei',
    component: DaftarSurvei
  },
  {
    path: '/surveionline',
    name: 'SurveiOnline',
    component: SurveiOnline
  },
  {
    path: '/hasilsurvei',
    name: 'HasilSurvei',
    component: HasilSurvei
  },
  {
    path: '/publikasihasilsurvei',
    name: 'PublikasiHasilSurvei',
    component: PublikasiHasilSurvei
  },
  {
    path: '/search/:searchQuery?',
    name: 'Search',
    component: Search,
    props: true,

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
